import Navegacion from "./components/Navegacion";
import { ChakraProvider } from "@chakra-ui/react";
import Footer from "./components/Footer";
import { Route, Routes, useLocation } from "react-router-dom";
import Bodega from "./pages/Bodega";
import Home from "./pages/Home";
import Fincas from "./pages/Fincas";
import NuestroEquipo from "./pages/NuestroEquipo";
import Vinos from "./pages/Vinos";
import Tours from "./pages/Tours";
import { customTheme } from "./utils/theme";
import Contacto from "./pages/Contacto";
import Certificaciones from "./pages/Certificaciones";
import Sustentabilidad from "./pages/Sustentabilidad";
import { useState, useEffect, createContext } from "react";
import MayorDeEdadCheck from "./pages/MayorDeEdadCheck";
import { AnimatePresence } from "framer-motion";
import DetalleVino from "./pages/DetalleVino";

export const LanguajeContext = createContext(null);

function App() {
  const [isOver18, setIsOver18] = useState(
    () => JSON.parse(localStorage.getItem("MDE")) || false
  );

  useEffect(() => {
    localStorage.setItem("MDE", JSON.stringify(isOver18));
  }, [isOver18]);

  const location = useLocation();

  const [lang, setLang] = useState(
    () => JSON.parse(localStorage.getItem("lang")) || 0
  );
  return (
    <ChakraProvider theme={customTheme}>
      <LanguajeContext.Provider value={{ lang, setLang }}>
        {isOver18 ? (
          <>
            <Navegacion />
            <AnimatePresence mode="wait" exitBeforeEnter>
              <Routes location={location} key={location.pathname}>
                <Route path="/" element={<Home />} />
                <Route path="/bodega" element={<Bodega />} />
                <Route path="/fincas" element={<Fincas />} />
                <Route path="/equipo" element={<NuestroEquipo />} />
                <Route path="/vinos" element={<Vinos />} />
                <Route path="/vinos/:id" element={<Vinos />} />
                <Route path="/tours" element={<Tours />} />
                <Route path="/contacto" element={<Contacto />} />
                <Route path="/certificaciones" element={<Certificaciones />} />
                <Route path="/sustentabilidad" element={<Sustentabilidad />} />
                <Route path="/vino/:id" element={<DetalleVino />} />
              </Routes>
            </AnimatePresence>
            <Footer />
          </>
        ) : (
          <>
            <Routes>
              <Route
                path="/"
                element={<MayorDeEdadCheck setIsOver18={setIsOver18} />}
              />
            </Routes>
            <Footer />
          </>
        )}
      </LanguajeContext.Provider>
    </ChakraProvider>
  );
}

export default App;
