import { createIcon } from "@chakra-ui/react";

export const CheckIcon = createIcon({
  displayName: "CheckIcon",
  viewBox: "0 0 19 19",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.9375 9.75C1.9375 7.8106 2.70792 5.95064 4.07928 4.57928C5.45064 3.20792 7.3106 2.4375 9.25 2.4375C11.1894 2.4375 13.0494 3.20792 14.4207 4.57928C15.7921 5.95064 16.5625 7.8106 16.5625 9.75C16.5625 11.6894 15.7921 13.5494 14.4207 14.9207C13.0494 16.2921 11.1894 17.0625 9.25 17.0625C7.3106 17.0625 5.45064 16.2921 4.07928 14.9207C2.70792 13.5494 1.9375 11.6894 1.9375 9.75ZM0.25 9.75C0.25 7.36305 1.19821 5.07387 2.88604 3.38604C4.57387 1.69821 6.86305 0.75 9.25 0.75C11.6369 0.75 13.9261 1.69821 15.614 3.38604C17.3018 5.07387 18.25 7.36305 18.25 9.75C18.25 12.1369 17.3018 14.4261 15.614 16.114C13.9261 17.8018 11.6369 18.75 9.25 18.75C6.86305 18.75 4.57387 17.8018 2.88604 16.114C1.19821 14.4261 0.25 12.1369 0.25 9.75ZM13.5025 7.815C13.6515 7.65505 13.7327 7.4435 13.7288 7.22491C13.725 7.00632 13.6364 6.79776 13.4818 6.64318C13.3272 6.48859 13.1187 6.40003 12.9001 6.39618C12.6815 6.39232 12.4699 6.47346 12.31 6.6225L7.84375 11.0887L6.19 9.435C6.03005 9.28596 5.8185 9.20482 5.59991 9.20868C5.38132 9.21253 5.17276 9.30109 5.01818 9.45568C4.86359 9.61026 4.77503 9.81882 4.77118 10.0374C4.76732 10.256 4.84846 10.4676 4.9975 10.6275L7.2475 12.8775C7.4057 13.0355 7.62016 13.1243 7.84375 13.1243C8.06734 13.1243 8.2818 13.0355 8.44 12.8775L13.5025 7.815Z"
      fill="#CECECE"
    />
  ),
});
