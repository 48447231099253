import { Container } from "@chakra-ui/react";
import React from "react";
import HeaderBannerSmall from "../components/HeaderBannerSmall";
import SustentabilidadPrimero from "../components/SustentabilidadPrimero";
import SustentabilidadSegundo from "../components/SustentabilidadSegundo";
import SustentabilidadTercero from "../components/SustentabilidadTercero";
import { motion } from "framer-motion";
import { opacityRouteVariants } from "../utils/animations";
import { useContext } from "react";
import { LanguajeContext } from "../App";
import { generarTextosSustentabilidad } from "../utils/textosSustentabilidad";

const Sustentabilidad = () => {
  const { lang } = useContext(LanguajeContext);
  const {
    sustentabilidadSegundoList,
    sustentabilidadTerceroList,
    sustentabilidadPrimeroParagraphs,
    sustentabilidadTerceroParagraphs,
  } = generarTextosSustentabilidad(lang);
  return (
    <motion.div
      variants={opacityRouteVariants}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <HeaderBannerSmall
        image={"./img/sus-header.jpg"}
        title={lang ? "Sustainability" : "Sustentabilidad"}
      />
      <Container maxW={"container.lg"}>
        <SustentabilidadPrimero
          title={lang ? "Our objective at" : "El objetivo que tenemos en"}
          salentein={"Salentein Family of Wines "}
          subtitle={lang ? "is clear:" : "es claro:"}
          firstParagraph={sustentabilidadPrimeroParagraphs[0]}
          secondParagraph={sustentabilidadPrimeroParagraphs[1]}
          thirdParagraph={sustentabilidadPrimeroParagraphs[2]}
        />
        <SustentabilidadSegundo
          title={
            lang ? "How our wineries benefit" : "Aportes de nuestras Bodegas"
          }
          subtitle={
            lang
              ? "in the pursuit of sustainability:"
              : "en pos de la sustentabilidad:"
          }
          listItems={sustentabilidadSegundoList}
        />
        <SustentabilidadTercero
          title={
            lang
              ? "We at Salentein Family of Wines: "
              : "En Salentein Family of Wines: "
          }
          subtitle={
            lang
              ? "respect the United Nations' SDGs"
              : "respetamos los ODS de Naciones Unidas"
          }
          topParagraph={sustentabilidadTerceroParagraphs.top}
          listItems={sustentabilidadTerceroList}
          bottomParagraph={sustentabilidadTerceroParagraphs.bottom}
        />
      </Container>
    </motion.div>
  );
};

export default Sustentabilidad;
