import React from "react";
import {
  Heading,
  Image,
  Container,
  Box,
  Text,
  Divider,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import {
  opacityNoMoveVariants,
  opacityStaggerVariants,
  opacityStaggerVariantsImage,
  opacityVariants,
} from "../utils/animations";

const UnEquipo = ({
  puesto,
  nombre,
  descripcion1,
  descripcion2,
  variant,
  fondo,
  cara,
}) => {
  if (variant === "izquierda") {
    return (
      <AnimatePresence>
        <Container maxW={"container.xl"} pb={16} px={0}>
          <Grid
            templateColumns={{ lg: "repeat(2,1fr)", xs: "repeat(1,1fr)" }}
            py={5}
          >
            <GridItem>
              <motion.div
                variants={opacityVariants}
                initial={"hidden"}
                animate={"show"}
                exit={"exit"}
                viewport={{ once: true }}
              >
                <Box
                  px={{ xl: 24, lg: 16, md: 12, xs: 4 }}
                  py={{ xl: 20, lg: 12, xs: 12 }}
                >
                  <Text
                    fontSize={"12px"}
                    color={"rojo"}
                    textTransform={"uppercase"}
                    letterSpacing={"0.01em"}
                    fontFamily={"Montserrat"}
                  >
                    {puesto}
                  </Text>
                  <Heading
                    fontSize={"36px"}
                    color={"rojo"}
                    fontWeight={"700"}
                    fontFamily={"Playfair Display"}
                    letterSpacing={"0.02em"}
                  >
                    {nombre}
                  </Heading>
                  <Divider
                    borderColor={"#000"}
                    my={5}
                    maxW={"28%"}
                    borderBottomWidth={"2px"}
                  />
                  <Text
                    fontSize={"14px"}
                    lineHeight="22px"
                    color={"#393835"}
                    fontFamily={"Montserrat"}
                  >
                    {descripcion1}
                    <br />
                    <br />
                  </Text>
                  <Text
                    fontSize={"14px"}
                    lineHeight="22px"
                    color={"#393835"}
                    fontFamily={"Montserrat"}
                  >
                    {descripcion2}
                  </Text>
                </Box>
              </motion.div>
            </GridItem>
            <GridItem
              position={"relative"}
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
            >
              <Box
                height={{ lg: "600px", xs: "600px" }}
                pos={"relative"}
                w="100%"
              >
                <motion.div
                  variants={opacityStaggerVariantsImage}
                  initial={"hidden"}
                  animate={"show"}
                  exit={"exit"}
                  viewport={{ once: true }}
                >
                  <motion.div variants={opacityNoMoveVariants}>
                    <Image
                      src={fondo}
                      pos={"absolute"}
                      left={{ lg: "0", xs: "5%" }}
                    />
                  </motion.div>
                  <motion.div variants={opacityNoMoveVariants}>
                    <Image
                      src={cara}
                      pos={"absolute"}
                      right={{ lg: "0%", xs: "5%" }}
                      top={"10%"}
                    />
                  </motion.div>
                </motion.div>
              </Box>
            </GridItem>
          </Grid>
        </Container>
      </AnimatePresence>
    );
  }
  return (
    <AnimatePresence>
      <Container maxW={"container.xl"} pb={16} px={0}>
        <Grid templateColumns={{ lg: "repeat(2,1fr)", xs: "repeat(1,1fr)" }}>
          <GridItem
            position={"relative"}
            order={{ lg: 1, xs: 2 }}
            display={"flex"}
            alignItems="center"
            justifyContent={"center"}
          >
            <Box
              height={{ lg: "600px", xs: "600px" }}
              pos={"relative"}
              w="100%"
            >
              <motion.div
                variants={opacityStaggerVariantsImage}
                initial={"hidden"}
                animate={"show"}
                exit={"exit"}
                viewport={{ once: true }}
              >
                <motion.div variants={opacityNoMoveVariants}>
                  <Image
                    src={fondo}
                    pos={"absolute"}
                    left={{ lg: "0%", xs: "5%" }}
                  />
                </motion.div>
                <motion.div variants={opacityNoMoveVariants}>
                  <Image
                    src={cara}
                    pos={"absolute"}
                    right={{ lg: "0%", xs: "5%" }}
                    bottom={{ lg: "0%", md: "0%", xs: "0%" }}
                  />
                </motion.div>
              </motion.div>
            </Box>
          </GridItem>
          <GridItem order={{ lg: 2, xs: 1 }}>
            <motion.div
              variants={opacityVariants}
              initial={"hidden"}
              animate={"show"}
              exit={"exit"}
              viewport={{ once: true }}
            >
              <Box
                px={{ xl: 24, lg: 16, md: 12, xs: 4 }}
                py={{ xl: 20, lg: 12, xs: 12 }}
              >
                <Text
                  fontSize={"12px"}
                  color={"rojo"}
                  textTransform={"uppercase"}
                  letterSpacing={"0.01em"}
                  fontFamily={"Montserrat"}
                >
                  {puesto}
                </Text>
                <Heading
                  fontSize={"36px"}
                  color={"rojo"}
                  fontWeight={"700"}
                  fontFamily={"Playfair Display"}
                  letterSpacing={"0.02em"}
                >
                  {nombre}
                </Heading>
                <Divider
                  borderColor={"#000"}
                  my={5}
                  maxW={"28%"}
                  borderBottomWidth={"2px"}
                />
                <Text
                  fontSize={"14px"}
                  lineHeight="22px"
                  color={"#393835"}
                  fontFamily={"Montserrat"}
                >
                  {descripcion1}
                  <br />
                  <br />
                </Text>
                <Text
                  fontSize={"14px"}
                  lineHeight="22px"
                  color={"#393835"}
                  fontFamily={"Montserrat"}
                >
                  {descripcion2}
                </Text>
              </Box>
            </motion.div>
          </GridItem>
        </Grid>
      </Container>
    </AnimatePresence>
  );
};

export default UnEquipo;
