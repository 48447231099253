import { Button } from "@chakra-ui/react";
import { DownloadIcon } from "./icons/Download";
import { FotoIcon } from "./icons/Foto";
import { useContext } from "react";
import { LanguajeContext } from "../App";

const apiImages = "/api/";
const DescargarButton = ({ variant, dl }) => {
  const { lang } = useContext(LanguajeContext);
  if (variant === "ficha") {
    return (
      <a href={apiImages + dl} download>
        <Button
          colorScheme={"black"}
          variant="solid"
          borderRadius={"0px"}
          fontSize={"14px"}
          _hover={{ backgroundColor: "#000" }}
          leftIcon={<DownloadIcon />}
        >
          {lang ? "DOWNLOAD FILE" : "DESCARGAR FICHA"}
        </Button>
      </a>
    );
  }
  return (
    <a href={apiImages + dl} download>
      <Button
        colorScheme={"black"}
        variant="solid"
        borderRadius={"0px"}
        fontSize={"14px"}
        _hover={{ backgroundColor: "#000" }}
        leftIcon={<FotoIcon />}
      >
        {lang ? "DOWNLOAD PHOTO" : "DESCARGAR FOTO"}
      </Button>
    </a>
  );
};

export default DescargarButton;
