import { Divider, VStack, Text, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { titleCase } from "../utils/functions";

function slugify(str) {
  return String(str)
    .normalize("NFKD") // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
    .replace(/\s+/g, "-") // replace spaces with hyphens
    .replace(/-+/g, "-"); // remove consecutive hyphens
}

const UnVino = ({ nombre, imagen, id }) => {
  return (
    <motion.div
      whileHover={{
        scale: 1.2,
        transition: { duration: 0.3 },
      }}
      whileTap={{ scale: 0.9 }}
    >
      <Link to={`/vino/${slugify(nombre)}`} state={{ id }}>
        <Box px={5}>
          <VStack justifyContent={"center"} alignItems={"center"} pb={10}>
            <img
              src={imagen}
              alt=""
              style={{ maxWidth: "100%", height: "312px" }}
            />
            <Divider borderColor={"#393835"} />
            <Text
              fontFamily={"Playfair Display"}
              color={"#393835"}
              fontSize={"21px"}
              fontWeight={"bold"}
              textAlign={"center"}
            >
              {titleCase(nombre)}
            </Text>
          </VStack>
        </Box>
      </Link>
    </motion.div>
  );
};

export default UnVino;
