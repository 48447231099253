import {
  Divider,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
  Stack,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import DescargarButton from "../components/DescargarButton";
import { getDetalleVino } from "../utils/APICalls";
import { titleCase } from "../utils/functions";
import {
  ChakraBox,
  opacityVariants,
  opacityRouteVariants,
} from "../utils/animations";
import { LanguajeContext } from "../App";

const apiImages = "/api/";
const DetalleVino = () => {
  let location = useLocation();
  const { id } = location.state || 1;
  const [infoVino, setInfoVino] = useState();
  const { lang } = useContext(LanguajeContext);

  useEffect(() => {
    getDetalleVino(id, lang).then((json) => {
      setInfoVino(json.extraInfo);
    });
  }, [id, lang]);

  return (
    <motion.div
      variants={opacityRouteVariants}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Grid
        pt={32}
        templateColumns={{ lg: "repeat(2, 1fr)", xs: "repeat(1, 1fr)" }}
      >
        {infoVino && (
          <AnimatePresence key={infoVino.id}>
            <GridItem>
              <ChakraBox
                p={10}
                backgroundColor={"#927F00"}
                variants={opacityVariants}
                initial="hidden"
                animate="show"
                exit="exit"
              >
                <img
                  src={
                    lang
                      ? apiImages + infoVino.pathFotoME
                      : apiImages + infoVino.pathFoto
                  }
                  alt=""
                  style={{ width: "100%" }}
                />
              </ChakraBox>
            </GridItem>
            <GridItem>
              <ChakraBox
                variants={opacityVariants}
                initial="hidden"
                animate="show"
                exit="exit"
              >
                <VStack
                  pt={8}
                  alignItems={"flex-start"}
                  maxW={{ lg: "32.5vw", md: "70%", xs: "80%" }}
                  mx={"auto"}
                >
                  <Breadcrumbs
                    tipoVino={infoVino.idTipoVino}
                    vino={infoVino.nombre}
                  />
                  <HStack pt={8}>
                    <Text
                      fontFamily={"Playfair Display"}
                      fontSize={"44px"}
                      lineHeight={"42px"}
                      fontWeight={"bold"}
                      color={"#931414"}
                      flex={"0 0 0"}
                      pr={8}
                    >
                      {titleCase(infoVino.nombre)}
                    </Text>
                    <Divider
                      w={"50%"}
                      borderColor={"#931414"}
                      flex={"1 1 50px"}
                    />
                  </HStack>
                  <Text
                    fontFamily={"Montserrat"}
                    fontSize={"16px"}
                    lineHeight={"22px"}
                    color={"#000"}
                    py={10}
                  >
                    {infoVino.detalleVino.premios}
                  </Text>
                  <Stack
                    direction={{ md: "row", xs: "column" }}
                    alignSelf={{ md: "unset", xs: "center" }}
                    gap={{ md: "unset", xs: "15px" }}
                    pb={{ md: "unset", xs: "50px" }}
                  >
                    <DescargarButton
                      variant={"ficha"}
                      dl={infoVino.detalleVino.pathFicha}
                    />
                    <DescargarButton
                      variant={"foto"}
                      dl={lang ? infoVino.pathFotoME : infoVino.pathFoto}
                    />
                  </Stack>
                </VStack>
              </ChakraBox>
            </GridItem>
          </AnimatePresence>
        )}
      </Grid>
    </motion.div>
  );
};

export default DetalleVino;
