import { createIcon } from "@chakra-ui/react";

export const ArrowRightIcon = createIcon({
  displayName: "ArrowRightIcon",
  viewBox: "0 0 26 16",
  path: (
    <path
      d="M0 8.375H24.5M24.5 8.375L17.5 1M24.5 8.375L17.5 15.5"
      stroke="black"
      strokeWidth={"2px"}
    />
  ),
});

export const ArrowRightRedIcon = createIcon({
  displayName: "ArrowRightRedIcon",
  viewBox: "0 0 25 17",
  path: (
    <path
      d="M0 8.625H24.5M24.5 8.625L17.5 1.25M24.5 8.625L17.5 15.75"
      stroke="#931414"
      strokeLinejoin="round"
      strokeWidth={"2px"}
    />
  ),
});
