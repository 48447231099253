import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";

const SustentabilidadTercero = ({
  title,
  subtitle,
  topParagraph,
  listItems,
  bottomParagraph,
}) => {
  return (
    <Box pt={20} px={{ lg: 0, md: 20, xs: 8 }}>
      <Text
        fontFamily={"Playfair Display"}
        fontWeight={"700"}
        fontSize={"26px"}
        color={"calliaGray"}
        pb={5}
      >
        <span style={{ color: "#931414", fontStyle: "italic" }}>{title}</span>
        {subtitle}
      </Text>
      <Text
        fontSize={"13px"}
        fontFamily={"Montserrat"}
        lineHeight={"21px"}
        color={"calliaGray"}
        pb={4}
      >
        {topParagraph}
      </Text>
      <UnorderedList
        fontSize={"13px"}
        fontFamily={"Montserrat"}
        lineHeight={"21px"}
        color={"calliaGray"}
      >
        {listItems.map((unItem) => (
          <ListItem pb={4}>
            <span style={{ fontWeight: "bold" }}>{unItem.title}</span>{" "}
            {unItem.text}
          </ListItem>
        ))}
      </UnorderedList>
      <Text
        fontSize={"13px"}
        fontFamily={"Montserrat"}
        lineHeight={"21px"}
        color={"calliaGray"}
        pb={10}
      >
        {bottomParagraph}
      </Text>
    </Box>
  );
};

export default SustentabilidadTercero;
