import React, { useState } from "react";
import UnEquipo from "../components/UnEquipo";
import HeaderBannerSmall from "../components/HeaderBannerSmall";
import { motion } from "framer-motion";
import { opacityRouteVariants } from "../utils/animations";
import { useEffect } from "react";
import { getEquipo } from "../utils/APICalls";
import { useContext } from "react";
import { LanguajeContext } from "../App";
const apiImages = "api/";
const NuestroEquipo = () => {
  const [listadoEquipos, setListadoEquipos] = useState();
  const { lang } = useContext(LanguajeContext);
  useEffect(() => {
    getEquipo(lang).then((json) => setListadoEquipos(json.extraInfo));
  }, [lang]);
  return (
    <motion.div
      variants={opacityRouteVariants}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <HeaderBannerSmall
        title={lang ? "Our team" : "Nuestro equipo"}
        subtitle={
          "Est commodo in pulvinar varius. Neque est lobortis purus volutpat egestas."
        }
        image={"./img/equipo-header.jpg"}
      />

      {listadoEquipos &&
        listadoEquipos.map((unItem, i) => {
          if (i % 2 === 0) {
            return (
              <UnEquipo
                puesto={unItem.perfil.rol}
                nombre={unItem.nombre}
                key={unItem.id}
                fondo={apiImages + unItem.backgroundFoto}
                cara={apiImages + unItem.pathFoto}
                descripcion1={unItem.perfil.descripcion}
                descripcion2={""}
              />
            );
          }
          return (
            <UnEquipo
              variant={"izquierda"}
              puesto={unItem.perfil.rol}
              nombre={unItem.nombre}
              key={unItem.id}
              fondo={apiImages + unItem.backgroundFoto}
              cara={apiImages + unItem.pathFoto}
              descripcion1={unItem.perfil.descripcion}
              descripcion2={""}
            />
          );
        })}
    </motion.div>
  );
};

export default NuestroEquipo;
