import React from "react";
import {
  Container,
  Box,
  Image,
  Text,
  Heading,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { ChakraBox, swipeToLeftVariants } from "../utils/animations";

const ValleTulum = ({ isVisible, title, texto, textoExtra }) => {
  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <ChakraBox
          variants={swipeToLeftVariants}
          initial="hidden"
          animate="show"
          exit="exit"
          pos={"absolute"}
          right={"0"}
          left={"0"}
          top={"0"}
        >
          <Container
            maxW={"container.xl"}
            position={"relative"}
            opacity={isVisible ? "100" : "0"}
            transition={"opacity .3s ease-in"}
          >
            <Box background={"crema"} boxShadow={"10px 10px 15px #999"}>
              <Box position={"relative"} p={10}>
                <Heading
                  top={{ md: "-50%", xs: "-70%" }}
                  mx={"auto"}
                  left={"0"}
                  right={"0"}
                  fontSize={{ md: "6xl", xs: "5xl" }}
                  lineHeight={{ md: "82.65px", xs: "50px" }}
                  textAlign={"center"}
                  color="rojo"
                  fontStyle={"italic"}
                  fontFamily={"'Playfair Display', serif"}
                  position={"absolute"}
                >
                  {title}
                </Heading>
              </Box>
              <Grid
                templateColumns={{ xl: "repeat(2,1fr)", xs: "repeat(1,1fr)" }}
                pb={16}
              >
                <GridItem
                  display={{ xl: "initial", xs: "flex" }}
                  justifyContent={"center"}
                  pb={{ xl: 0, xs: 8 }}
                >
                  <Image
                    src="./img/tulum-1.webp"
                    position={{ xl: "absolute", xs: "initial" }}
                    left={"0"}
                  />
                </GridItem>
                <GridItem>
                  <Text
                    maxW={"90%"}
                    fontFamily={"'Montserrat', sans-serif"}
                    fontSize={"14px"}
                    lineHeight={"22px"}
                    color={"#000"}
                    px={{ xl: 16, xs: 0 }}
                    mx={{ xl: 0, xs: "auto" }}
                    pt={2}
                    textAlign={{ xl: "left", xs: "center" }}
                  >
                    {texto.map((unParrafo, i) => {
                      if (i === texto.length) {
                        return <Text>{unParrafo}</Text>;
                      }

                      return <Text pb={4}>{unParrafo}</Text>;
                    })}
                  </Text>
                </GridItem>
              </Grid>
              <Grid
                templateColumns={{ lg: "repeat(2, 1fr)", xs: "repeat(1, 1fr)" }}
              >
                <GridItem order={{ lg: 1, xs: 2 }}>
                  <Text
                    pb={{ lg: 32 }}
                    px={{ lg: 16, xs: 0 }}
                    mx={{ lg: 0, xs: "auto" }}
                    maxW={{ lg: "100%", xs: "90%" }}
                    pt={8}
                    fontFamily={"'Montserrat', sans-serif"}
                    fontSize={"14px"}
                    lineHeight={"22px"}
                    color={"#000"}
                    textAlign={{ lg: "left", xs: "center" }}
                  >
                    {textoExtra.map((unTexto) => (
                      <Text pb={6}>
                        <span className="fw600">{unTexto.title}: </span>
                        {unTexto.text}
                      </Text>
                    ))}
                  </Text>
                </GridItem>
                <GridItem
                  order={{ lg: 2, xs: 1 }}
                  display={{ lg: "initial", xs: "flex" }}
                  justifyContent={"center"}
                  pb={{ xl: 0, xs: 8 }}
                >
                  <Image
                    src="./img/tulum.jpg"
                    position={{ xl: "absolute", xs: "initial" }}
                    right={"0"}
                    pt={{ xl: 0, lg: 8 }}
                  />
                </GridItem>
              </Grid>
            </Box>
          </Container>
        </ChakraBox>
      )}
    </AnimatePresence>
  );
};

export default ValleTulum;
