import { isValidMotionProp, motion } from "framer-motion";
import { chakra, shouldForwardProp } from "@chakra-ui/react";

export const ChakraBox = chakra(motion.div, {
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const swipeToLeftVariants = {
  hidden: {
    x: "-100vw",
    opacity: 0,
  },
  show: {
    x: 0,
    zIndex: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    x: "100vw",
    zIndex: 0,
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export const opacityStaggerVariants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      duration: "0.1",
      staggerChildren: 0.1,
    },
  },
};

export const comeFromTopVariants = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      stiffness: 45,
    },
  },
};

export const comeFromRightVariants = {
  hidden: {
    x: "100vw",
    opacity: 0,
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 50,
    },
  },
};

export const opacityRouteVariants = {
  hidden: { opacity: 0.5 },
  show: {
    opacity: 1,
    transition: { duration: 0.5, when: "afterChildren" },
  },
  exit: { opacity: 0.5, transition: { duration: 0.5 } },
};

export const opacityVariants = {
  hidden: { opacity: 0, x: -75 },
  show: {
    opacity: 1,
    x: 0,
    transition: { type: "spring", stiffness: 20, when: "beforeChildren" },
  },
};

export const opacityStaggerVariantsImage = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      duration: "0.1",
      staggerChildren: 0.15,
    },
  },
};

export const opacityNoMoveVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
};
