import { createIcon } from "@chakra-ui/react";

export const FotoIcon = createIcon({
  displayName: "FotoIcon",
  viewBox: "0 0 21 17",
  path: (
    <path
      d="M18.4062 2.83984H15.3516L14.6238 0.800391C14.5736 0.660836 14.4814 0.540207 14.36 0.454981C14.2386 0.369755 14.0938 0.324088 13.9455 0.324219H7.05449C6.75127 0.324219 6.47949 0.515137 6.37842 0.800391L5.64844 2.83984H2.59375C1.60098 2.83984 0.796875 3.64394 0.796875 4.63672V14.8789C0.796875 15.8717 1.60098 16.6758 2.59375 16.6758H18.4062C19.399 16.6758 20.2031 15.8717 20.2031 14.8789V4.63672C20.2031 3.64394 19.399 2.83984 18.4062 2.83984ZM10.5 13.082C8.51445 13.082 6.90625 11.4738 6.90625 9.48828C6.90625 7.50273 8.51445 5.89453 10.5 5.89453C12.4855 5.89453 14.0938 7.50273 14.0938 9.48828C14.0938 11.4738 12.4855 13.082 10.5 13.082ZM8.34375 9.48828C8.34375 10.0601 8.57093 10.6086 8.9753 11.013C9.37968 11.4174 9.92813 11.6445 10.5 11.6445C11.0719 11.6445 11.6203 11.4174 12.0247 11.013C12.4291 10.6086 12.6562 10.0601 12.6562 9.48828C12.6562 8.9164 12.4291 8.36795 12.0247 7.96358C11.6203 7.5592 11.0719 7.33203 10.5 7.33203C9.92813 7.33203 9.37968 7.5592 8.9753 7.96358C8.57093 8.36795 8.34375 8.9164 8.34375 9.48828Z"
      fill="white"
    />
  ),
});
