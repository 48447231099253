import {
  Box,
  HStack,
  Image,
  Select,
  Text,
  Button,
  VStack,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { useState, useContext } from "react";
import { LanguajeContext } from "../App";
const MayorDeEdadCheck = ({ setIsOver18 }) => {
  const [esMayor, setEsMayor] = useState("si");
  const [chosenLanguaje, setChosenLanguaje] = useState(0);

  const { setLang } = useContext(LanguajeContext);
  const handleSubmit = (esMayor) => {
    if (esMayor === "si") {
      setLang(chosenLanguaje);
      localStorage.setItem("lang", chosenLanguaje);
      setIsOver18(true);
    } else {
      alert("Debe ser mayor para continuar");
    }
  };

  return (
    <Box
      width="100%"
      backgroundImage={"./img/mde.png"}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"100% 100%"}
      py={16}
    >
      <VStack spacing={8}>
        <Image src="./img/logo-callia-color.svg" maxW={"200px"} />
        <VStack
          border={"2px solid #fff"}
          py={6}
          px={20}
          textAlign={"center"}
          spacing={4}
        >
          <Text
            fontWeight={"bold"}
            fontSize={"18px"}
            lineHeight={"23px"}
            fontFamily={"Montserrat"}
            color={"#fff"}
          >
            SOY MAYOR DE 18 AÑOS <br />I AM OVER 18 YEARS
          </Text>
          <RadioGroup onChange={setEsMayor} value={esMayor}>
            <HStack
              spacing={5}
              fontWeight={"bold"}
              fontSize={"18px"}
              lineHeight={"23px"}
              fontFamily={"Montserrat"}
              color={"#fff"}
            >
              <Radio value={"si"} colorScheme={"calliaRed"}>
                SI / YES
              </Radio>
              <Radio value={"no"} colorScheme={"calliaRed"}>
                NO
              </Radio>
            </HStack>
          </RadioGroup>
          <Text
            fontWeight={"bold"}
            fontSize={"15px"}
            lineHeight={"25px"}
            fontFamily={"Montserrat"}
            color={"#fff"}
          >
            Idioma / Language:
          </Text>
          <Select
            size={"lg"}
            borderRadius={"0"}
            borderColor={"#fff"}
            textAlign={"center"}
            color={"#fff"}
            value={chosenLanguaje}
            onChange={(event) => {
              setChosenLanguaje(event.target.value);
              console.log(chosenLanguaje);
            }}
          >
            <option
              value={0}
              style={{
                color: "white",
                background: "#000",
                fontFamily: "Montserrat",
                textTransform: "uppercase",
                fontSize: "16px",
                lineHeight: "23px",
                padding: "5px 0px",
                border: "0",
                outline: "0",
              }}
            >
              Español
            </option>
            <option
              value={1}
              style={{
                color: "white",
                background: "#000",
                fontFamily: "Montserrat",
                textTransform: "uppercase",
                fontSize: "16px",
                lineHeight: "23px",
                padding: "5px 0px",
                border: "0",
                outline: "0",
              }}
            >
              English
            </option>
          </Select>
        </VStack>
        <Button
          color={"calliaGray"}
          fontWeight={"bold"}
          fontSize={"15px"}
          lineHeight={"25px"}
          fontFamily={"Montserrat"}
          textTransform={"uppercase"}
          _hover={{ backgroundColor: "calliaGray", color: "#fff" }}
          onClick={() => handleSubmit(esMayor)}
        >
          Ingresar al sitio
        </Button>
      </VStack>
    </Box>
  );
};

export default MayorDeEdadCheck;
