import { createBreakpoints } from "@chakra-ui/theme-tools";
import { extendTheme } from "@chakra-ui/react";
const breakpoints = createBreakpoints({
  xs: "0px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  "2xl": "1400px",
  "3xl": "1600px",
});

export const customTheme = extendTheme({
  breakpoints: breakpoints,
  colors: {
    black: {
      100: "rgba(57, 56, 53, 1)",
      200: "rgba(57, 56, 53, 1)",
      300: "rgba(57, 56, 53, 1)",
      400: "rgba(57, 56, 53, 1)",
      500: "rgba(57, 56, 53, 1)",
      600: "rgba(57, 56, 53, 1)",
      700: "rgba(57, 56, 53, 1)",
      800: "rgba(57, 56, 53, 1)",
      900: "rgba(57, 56, 53, 1)",
    },
    calliaRed: {
      100: "rgba(147, 20, 20, 1)",
      200: "rgba(147, 20, 20, 1)",
      300: "rgba(147, 20, 20, 1)",
      400: "rgba(147, 20, 20, 1)",
      500: "rgba(147, 20, 20, 1)",
      600: "rgba(147, 20, 20, 1)",
      700: "rgba(147, 20, 20, 1)",
      800: "rgba(147, 20, 20, 1)",
      900: "rgba(147, 20, 20, 1)",
    },
  },
  semanticTokens: {
    colors: {
      bgPrimary: "#B6B6B6",
      calliaGray: "rgba(57, 56, 53, 1)",
      crema: "#F8EFE7",
      rojo: "rgba(147, 20, 20, 1)",
    },
  },
});
