import { useState } from "react";
import HeaderBannerSmall from "../components/HeaderBannerSmall";
import { Box, HStack, Text } from "@chakra-ui/react";
import ValleTulum from "../components/ValleTulum";
import VallePedernal from "../components/VallePedernal";
import ValleZonda from "../components/ValleZonda";
import ValleCalingasta from "../components/ValleCalingasta";
import { motion } from "framer-motion";
import { opacityRouteVariants } from "../utils/animations";

import { useContext } from "react";
import { LanguajeContext } from "../App";
import { generarTextosValles } from "../utils/textosValles";

const TabItem = ({ id, title, activeTab, setActiveTab }) => {
  const handleClick = () => {
    setActiveTab(id);
  };

  return (
    <Text
      color={"rgba(147, 20, 20, 1)"}
      transition={"all .3s"}
      fontFamily={"Montserrat"}
      fontSize={{ md: "lg", xs: "sm" }}
      fontWeight={activeTab === id ? "600" : "400"}
      fontStyle={"italic"}
      cursor={"pointer"}
      _hover={{
        fontWeight: "600",
      }}
      onClick={handleClick}
      borderBottom={
        activeTab === id ? "2px solid rgba(147, 20, 20, 1)" : "none"
      }
    >
      {title}
    </Text>
  );
};
const Fincas = () => {
  const [activeTab, setActiveTab] = useState("1");

  const { lang } = useContext(LanguajeContext);
  const { textosValles, textosVallesExtra } = generarTextosValles(lang);

  return (
    <motion.div
      variants={opacityRouteVariants}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <HeaderBannerSmall
        title={lang ? "Estates" : "Terruños y Fincas"}
        image={"./img/fincas-header.jpg"}
      />
      <Box w="100%">
        <Text
          fontSize={"16px"}
          lineHeight={"28px"}
          fontFamily={"Montserrat, sans-serif"}
          textAlign={"center"}
          color={"calliaGray"}
          px={{ lg: 40, md: 3 }}
          pt={16}
        >
          {lang
            ? `Bodegas Callia owns two estates in the Tulum Valley: one in the Caucete district and the other in 9 de Julio district. To make its wines, the winery also sources grapes from other valleys in San Juan, a province dominated by mountain ranges that enclose the valleys, each with different characteristics and elevations but, nonetheless, share common denominators that allow for their near-natural cultivation.`
            : `Bodegas Callia cuenta con dos fincas propias en el Valle de Tulum: una
          en el departamento de Caucete y la otra en el de 9 de Julio. Para
          elaborar sus vinos se abastece también de uvas de diferentes valles de
          San Juan, provincia dominada por cadenas montañosas que encierran
          valles con distintas características y altitudes. Sin embargo, todos
          ellos poseen algunos denominadores comunes, que permiten una
          producción agrícola prácticamente natural.`}
        </Text>
      </Box>
      <Box pt={20} pb={24}>
        <HStack
          justifyContent={"center"}
          spacing={{ md: 10, xs: 2 }}
          textAlign={"center"}
          overflowX={{ md: "initial", xs: "scroll" }}
        >
          <TabItem
            activeTab={activeTab}
            id={"1"}
            setActiveTab={setActiveTab}
            title={lang ? "Tulum Valley" : "Valle de Tulum"}
          />
          <TabItem
            activeTab={activeTab}
            id={"2"}
            setActiveTab={setActiveTab}
            title={lang ? "Pedernal Valley" : "Valle de Pedernal"}
          />
          <TabItem
            activeTab={activeTab}
            id={"3"}
            setActiveTab={setActiveTab}
            title={lang ? "Zonda Valley" : "Valle de Zonda"}
          />
          <TabItem
            activeTab={activeTab}
            id={"4"}
            setActiveTab={setActiveTab}
            title={lang ? "Calingasta Valley" : "Valle de Calingasta"}
          />
        </HStack>
      </Box>
      <HStack
        height={
          activeTab === "1"
            ? "1350px"
            : activeTab === "2"
            ? "1350px"
            : activeTab === "3"
            ? "950px"
            : "750px"
        }
        width={"100vw"}
        position={"relative"}
      >
        <ValleTulum
          isVisible={activeTab === "1"}
          title={lang ? "Tulum Valley" : "Valle de Tulum"}
          texto={textosValles.tulum}
          textoExtra={textosVallesExtra.tulum}
        />
        <VallePedernal
          isVisible={activeTab === "2"}
          title={lang ? "Pedernal Valley" : "Valle de Pedernal"}
          texto={textosValles.pedernal}
          textoExtra={textosVallesExtra.pedernal}
        />
        <ValleZonda
          isVisible={activeTab === "3"}
          title={lang ? "Zonda Valley" : "Valle de Zonda"}
          texto={textosValles.zonda}
        />
        <ValleCalingasta
          isVisible={activeTab === "4"}
          title={lang ? "Calingasta Valley" : "Valle de Calingasta"}
          texto={textosValles.calingasta}
        />
      </HStack>
    </motion.div>
  );
};

export default Fincas;
