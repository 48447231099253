import {
  Container,
  Box,
  Heading,
  Image,
  Text,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import React from "react";
import { AnimatePresence } from "framer-motion";
import { ChakraBox, swipeToLeftVariants } from "../utils/animations";

const VallePedernal = ({ isVisible, title, texto, textoExtra }) => {
  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <ChakraBox
          variants={swipeToLeftVariants}
          initial="hidden"
          animate="show"
          exit="exit"
          pos={"absolute"}
          right={"0"}
          left={"0"}
          top={"0"}
        >
          <Container
            maxW={"container.xl"}
            position={"relative"}
            opacity={isVisible ? "100" : "0"}
            transition={"opacity .3s ease-in"}
          >
            <Box background={"crema"} boxShadow={"10px 10px 15px #999"}>
              <Box position={"relative"} p={10}>
                <Heading
                  top={{ md: "-50%", xs: "-70%" }}
                  mx={"auto"}
                  left={"0"}
                  right={"0"}
                  fontSize={{ md: "6xl", xs: "5xl" }}
                  lineHeight={{ md: "82.65px", xs: "50px" }}
                  textAlign={"center"}
                  color="rojo"
                  fontStyle={"italic"}
                  fontFamily={"'Playfair Display', serif"}
                  position={"absolute"}
                >
                  {title}
                </Heading>
              </Box>
              <Grid
                templateColumns={{ xl: "repeat(2, 1fr)", xs: "repeat(1,1fr)" }}
                pt={12}
                pb={28}
              >
                <GridItem
                  display={{ xl: "initial", xs: "flex" }}
                  justifyContent={"center"}
                >
                  <Image
                    src="./img/pedernal-1.jpg"
                    position={{ xl: "absolute", xs: "initial" }}
                    left={"0"}
                  />
                </GridItem>
                <GridItem>
                  <Text
                    fontFamily={"'Montserrat', sans-serif"}
                    fontSize={"14px"}
                    lineHeight={"22px"}
                    color={"#000"}
                    maxW={{ xl: "450px", xs: "85%" }}
                    textAlign={{ xl: "left", xs: "center" }}
                    mx={"auto"}
                    pt={{ xl: 2, xs: 12 }}
                  >
                    {texto.map((unParrafo, i) => {
                      if (i === texto.length) {
                        return <Text>{unParrafo}</Text>;
                      }

                      return <Text pb={4}>{unParrafo}</Text>;
                    })}
                  </Text>
                </GridItem>
              </Grid>
              <Grid
                templateColumns={{ xl: "repeat(2, 1fr)", xs: "repeat(1,1fr)" }}
              >
                <GridItem order={{ xl: 1, xs: 2 }}>
                  <Text
                    fontFamily={"'Montserrat', sans-serif"}
                    fontSize={"14px"}
                    lineHeight={"22px"}
                    color={"#000"}
                    maxW={{ xl: "450px", xs: "85%" }}
                    textAlign={{ xl: "left", xs: "center" }}
                    mx={"auto"}
                    pt={{ xl: 2, xs: 12 }}
                    pb={28}
                  >
                    {textoExtra.map((unTexto, i) => {
                      if (i === textoExtra.length) {
                        return <Text>{unTexto}</Text>;
                      }

                      return <Text pb={4}>{unTexto}</Text>;
                    })}
                  </Text>
                </GridItem>
                <GridItem
                  order={{ xl: 2, xs: 1 }}
                  display={{ xl: "initial", xs: "flex" }}
                  justifyContent={"center"}
                >
                  <Image
                    src="./img/pedernal-2.jpg"
                    position={{ xl: "absolute", xs: "initial" }}
                    right={"0"}
                  />
                </GridItem>
              </Grid>
            </Box>
          </Container>
        </ChakraBox>
      )}
    </AnimatePresence>
  );
};

export default VallePedernal;
