import { createIcon } from "@chakra-ui/react";

export const DownloadIcon = createIcon({
  displayName: "DownloadIcon",
  viewBox: "0 0 15 18",
  path: (
    <path
      d="M14.2084 6.625H10.3751V0.875H4.62508V6.625H0.791748L7.50008 13.3333L14.2084 6.625ZM0.791748 15.25V17.1667H14.2084V15.25H0.791748Z"
      fill="white"
    />
  ),
});
