import {
  Box,
  Grid,
  GridItem,
  Image,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  HStack,
  Divider,
} from "@chakra-ui/react";
const CertificacionesPrimero = ({
  title,
  subtitle,
  firstParagraph,
  listItems,
  secondParagraph,
  bottomText,
  sitios,
  salentein,
}) => {
  return (
    <Grid
      templateColumns={{ lg: "repeat(2, 1fr)", xs: "repeat(1, 1fr)" }}
      pt={10}
    >
      <GridItem
        pos={"relative"}
        order={{ lg: 1, xs: 2 }}
        height={{ lg: "inherit", md: "650px", xs: "450px" }}
      >
        <Image src="./img/certificaciones-fondo-1.jpg" pos={"absolute"} />
        <Image
          src="./img/certificaciones-img-1.webp"
          pos={"absolute"}
          right={{ xl: "0", lg: "-7%", xs: "0%" }}
          bottom={{ xl: "0", lg: "10", xs: "0" }}
        />
      </GridItem>
      <GridItem order={{ lg: 2, xs: 1 }}>
        <Box px={{ xl: 8, lg: 12, md: 6, xs: 4 }} pt={5} pb={{ lg: 0, xs: 12 }}>
          <Heading
            fontFamily={"Playfair Display"}
            fontSize={"31px"}
            lineHeight={"36px"}
            color={"#393835"}
            fontWeight={"bold"}
          >
            {title}{" "}
            <span
              style={{
                fontFamily: "Trajan Pro",
                color: "#383A37",
                letterSpacing: "0.05em",
                fontWeight: "400",
                fontStyle: "normal",
                textTransform: "uppercase",
              }}
            >
              <span style={{ fontSize: "36px" }}>S</span>alentein{" "}
              <span style={{ fontSize: "36px" }}>F</span>amily of{" "}
              <span style={{ fontSize: "36px" }}>W</span>ines
            </span>
          </Heading>
          <Text
            fontFamily={"Playfair Display"}
            fontSize={"36px"}
            lineHeight={"36px"}
            color={"rojo"}
            fontWeight={"600"}
            fontStyle={"italic"}
            mb={4}
          >
            {subtitle}
          </Text>
          <Text
            fontFamily={"Montserrat"}
            fontSize={"13px"}
            lineHeight={"21px"}
            color={"calliaGray"}
            mb={4}
          >
            {firstParagraph}
          </Text>
          <UnorderedList
            fontFamily={"Montserrat"}
            fontSize={"14px"}
            lineHeight={"21px"}
            color={"calliaGray"}
            fontWeight={"500"}
            mb={4}
            spacing={2}
          >
            {listItems.map((unItem) => (
              <ListItem>{unItem}</ListItem>
            ))}
          </UnorderedList>
          <Text
            fontFamily={"Montserrat"}
            fontSize={"13px"}
            lineHeight={"21px"}
            color={"calliaGray"}
            mb={4}
          >
            {secondParagraph}
          </Text>
          <Text
            fontFamily={"Montserrat"}
            fontSize={"14px"}
            lineHeight={"21px"}
            fontWeight={"600"}
            color={"calliaGray"}
            mb={4}
          >
            {bottomText}
          </Text>
          <HStack
            fontFamily={"Montserrat"}
            fontSize={"13px"}
            lineHeight={"21px"}
            fontWeight={"600"}
            color={"calliaGray"}
          >
            <Text>{sitios[0]}</Text>
            <Divider
              orientation="vertical"
              h={"13px"}
              borderWidth={"1px"}
              borderColor={"#000"}
            />
            <Text>{sitios[1]}</Text>
          </HStack>
        </Box>
      </GridItem>
    </Grid>
  );
};

export default CertificacionesPrimero;
