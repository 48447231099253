import { HStack, Box, Heading } from "@chakra-ui/react";
import React from "react";

const HeaderBannerSmall = ({ title, subtitle, image }) => {
  return (
    <Box
      width="100%"
      backgroundImage={image}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
      textAlign={"center"}
    >
      <Box py={48}>
        <HStack justifyContent={"center"} spacing={5} alignItems="center">
          <Heading
            fontWeight={"800"}
            fontSize={{ md: "5xl", xs: "4xl" }}
            color={"#fff"}
            fontFamily={"Playfair Display"}
          >
            {title}
          </Heading>
        </HStack>
      </Box>
    </Box>
  );
};

export default HeaderBannerSmall;
