import { createIcon } from "@chakra-ui/react";

export const ReservaIcon = createIcon({
  displayName: "ReservaIcon",
  viewBox: "0 0 22 21",
  path: (
    <path
      d="M2.11579 8H16.9263V10H19.0421V4C19.0421 2.9 18.09 2 16.9263 2H15.8684V0H13.7526V2H5.28947V0H3.17368V2H2.11579C0.941526 2 0.0105789 2.9 0.0105789 4L0 18C0 18.5304 0.222913 19.0391 0.6197 19.4142C1.01649 19.7893 1.55465 20 2.11579 20H9.52105V18H2.11579V8ZM2.11579 4H16.9263V6H2.11579V4ZM20.9886 14.28L20.2375 14.99L17.9948 12.87L18.7459 12.16C18.8438 12.0673 18.96 11.9937 19.088 11.9436C19.216 11.8934 19.3532 11.8676 19.4917 11.8676C19.6303 11.8676 19.7675 11.8934 19.8954 11.9436C20.0234 11.9937 20.1397 12.0673 20.2375 12.16L20.9886 12.87C21.4012 13.26 21.4012 13.89 20.9886 14.28ZM17.2437 13.58L19.4864 15.7L13.8796 21H11.6368V18.88L17.2437 13.58Z"
      fill="white"
    />
  ),
});
